.formModule {
  padding: 60px 0;

  &__title {
    font-size: 36px !important;
    line-height: 46px !important;
    color: #41465a;
    font-weight: 600 !important;
    margin: 0 !important;
    // background: #b4c6f5;

    @media only screen and (max-width: 991px) {
      font-size: 30px !important;
      line-height: 40px !important;
    }
  }

  &__content {
    margin-bottom: 60px;

    p {
      font-size: 16px !important;
      line-height: 22px !important;
      color: #41465a;
      font-weight: 600 !important;
      margin: 22px 0 0 !important;
    }
  }

  &__link {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    text-align: center;
    justify-content: center;
    div {
      color: #61a1c2;
      margin-left: 3px;
      text-decoration: none;
      font-weight: bold !important;
      cursor: pointer;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 25px 0;

    button {
      background-color: #3689b3;
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      text-transform: capitalize;
      padding: 8px 20px;
      border-radius: 5px;
      margin: 0;
      box-shadow: none;
      max-width: 125px;

      &:hover {
        box-shadow: none;
        background-color: #3689b3;
        color: #fff;
      }
    }
  }

  &__card {
    box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.15);
    padding: 30px;
    text-align: left;
    border-radius: 8px;

    &-title {
      font-size: 22px !important;
      line-height: 30px !important;
      color: #41465a;
      font-weight: 600 !important;
      margin: 0 0 22px !important;
      text-align: left;
      &.mb-50 {
        margin: 0 0 50px !important;
      }
    }
  }

  .formField {
    display: grid;
    grid-template-columns: 100px auto;
    gap: 8px 20px;
    // align-items: center;
    margin-bottom: 15px;

    @media only screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }

    & > label {
      font-size: 16px !important;
      line-height: 22px !important;
      color: #41465a;
      font-weight: 600 !important;
      display: block;
      padding-top: 10px !important;
    }

    .MuiFormControl-root {
      margin: 0 !important;
    }

    .MuiInputBase-input {
      box-sizing: border-box;
      background: #f4f6fd;
      border: none;
      height: 44px;
      border-radius: 6px;
    }

    fieldset {
      border: none;
      height: 48px;
      border-radius: 6px;
    }
    .errorText {
      font-size: 12px !important;
      color: red;
      margin: 4px 0 0 !important;
    }
  }
}
