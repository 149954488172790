.referralBlock {
  &__title {
    font-size: 16px !important;
    line-height: 24px !important;
    padding: 0 0 10px !important;
    margin-bottom: 35px !important;
    color: #333a40 !important;
    span {
      position: relative;
      display: inline-block;
      padding: 0 15px;
      &::before {
        content: "";
        height: 5px;
        width: 100%;
        background-color: #368bb5;
        display: block;
        border-radius: 15px 15px 0 0;
        position: absolute;
        bottom: -10px;
        left: 0;
      }
    }
  }
  &__content {
    p {
      margin: 5px 0 0;
      font-size: 16px !important;
      color: #898d90 !important;
    }
  }
  &__subtitle {
    font-size: 28px !important;
    line-height: 36px !important;
    font-weight: 600 !important;
    color: #333a40 !important;
  }
  &__card {
    background-color: #f1f1f1;
    border-radius: 12px;
    padding: 30px;
    margin-top: 35px;
    max-width: 1200px;
    &-title {
      font-size: 18px !important;
      line-height: 24px !important;
      font-weight: 600 !important;
      color: #333a40 !important;
      margin: 0 0 25px !important;
    }
    &-field {
      display: grid;
      grid-template-columns: auto 40px;
      gap: 20px;
      align-items: flex-end;
      button {
        background-color: #368bb5;
        border: 1px solid #368bb5;
        height: 40px;
        width: 40px;
        padding: 8px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 40px;
        svg {
          height: 22px;
          width: 22px;
          fill: #fff;
        }
        &:hover {
          svg {
            fill: #368bb5;
          }
        }
      }
      .MuiFormControl-root {
        margin-bottom: 0 !important;
      }
    }
  }
}
